<div class="box-lost-connection">
    <div class="lost-connection-container">
        <div class="lost-connection-inside-container">
            <div class="lost-connection-title">
                <div class="box-logo">
                    <div class="logo-container">
                        <div class="company-logo-container">
                            <img [src]="companyLogo" alt="Company Logo" />
                        </div>
                        <p class="company-name">{{ companyName }}</p>
                    </div>
                </div>
                <div class="lost-connection-text-container">
                    <div class="lost-connection-name">
                        <h1> {{ lostConnectionName }}</h1>
                    </div>
                    <div class="lost-connection-description-container">
                        <ul>
                            <li>{{ lostConnectionDescription[0] }}</li>
                            <li>{{ lostConnectionDescription[1] }}</li>
                        </ul>
                    </div>
                </div>
                <button id="id-reload" type="button" class="reload-button" (click)="reload()"> {{ reloadButton }} </button>
            </div>
            <div class="lost-connection-image-container">
                <img [src]="lostConnectionImage" alt="Lost connection image" />
            </div>
        </div>
    </div>
    <div class="decoration-image-container">
        <img [src]="lostConnectionDecoration" alt="Decoration image" />
    </div>
</div>

