import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-loading',
    standalone: true,
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss'
})
export class LoadingComponent {

    constructor(private title: Title) {
        this.title.setTitle('Loading...');
    }
}
