import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ConfigService } from "../../core/services/config.service";
import { WebSocketService } from "../../core/services/web-socket.service";
import { RouterService } from "../../core/services/router.service";

@Injectable({
    providedIn: 'root'
})
export class ProjectGuard implements CanActivate {

    constructor(
        private router: RouterService,
        private config: ConfigService,
        private socket: WebSocketService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        const projectId = route.paramMap.get('project_id');
        console.log(projectId);
        const id = this.config.getProjectId();
        console.log(id);

        // If reload lost-network page
        if (this.config.getProjectId() === projectId) {
            return true;
        }

        console.log(projectId)

        if (!projectId) {
            this.router.goToNotFound();
            return false;
        }

        this.config.setProjectId(projectId);

        return this.config.fetchConfiguration().then(config => {
            this.config.setProjectConfig(config.body);
            console.log('config set');
            return this.socket.initConnection().then(() => {
                return true;
            }).catch(e => {
                this.router.goToNotFound();
                console.log('error while setting configuration' + e.toString());
                return false;
            });
        }).catch(e => {
            this.router.goToNotFound();
            console.log('error while setting configuration' + e.toString());
            return false;
        });
    }
}
