import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";
import { MatButton, MatButtonModule } from "@angular/material/button";

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from "./shared/component.module";
import { StartPageDirective } from "./shared/directives/start-page.directive";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { MicrophoneService } from "./core/services/microphone.service";
import { OfflineComponent } from "./pages/offline/offline.component";
import { LoadingComponent } from "./pages/loading/loading.component";
import { ProjectGuard } from "./shared/guards/project.guard";

export function initializeApp(microphone: MicrophoneService) {
    return async (): Promise<any> => {
        await microphone.loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web@1.18.0/dist/ort.js');
        await microphone.loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.18/dist/bundle.min.js');
    }
}

@NgModule({
    declarations: [
        AppComponent,
        StartPageDirective,
        NotFoundComponent,
        OfflineComponent
    ],
    imports: [
        ComponentModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatTooltipModule,
        MatTooltip,
        MatButtonModule,
        MatButton,
        LoadingComponent
    ],
    providers: [
        ProjectGuard,
        MicrophoneService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [MicrophoneService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    exports: []
})

export class AppModule {}
