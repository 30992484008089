<div class="box-loader">
    <div class="gradient-bg">
        <div class="gradients-container">
            <div class="g1"></div>
            <div class="g2"></div>
            <div class="g3"></div>
            <div class="g4"></div>
        </div>
    </div>
    <div class="loader-container">
        <div class="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <p class="loading-text">Loading</p>
    </div>
</div>
