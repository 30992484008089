import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { MessageService } from "../../core/services/message.service";
import { WebSocketService } from "../../core/services/web-socket.service";
import { WebSocketMessage } from "../../core/models/webSocketMessage";
import { AnimationService } from "../../core/services/animation.service";
import { ConfigService } from "../../core/services/config.service";
import { RouterService } from "../../core/services/router.service";

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrl: './offline.component.scss'
})
export class OfflineComponent implements OnInit {
    companyLogo: string = "assets/icons/logo.svg"
    companyName: string = "Exafy"
    lostConnectionImage: string = "assets/images/offline.png"
    lostConnectionName: string = "Offline"
    lostConnectionDescription: string[] = ["Check network cables, modem and router", "Reconnect to Wi-Fi"]
    lostConnectionDecoration: string = "assets/images/decoration.png"

    reloadButton: string = "Reload"

    constructor (
        private message: MessageService,
        private socket: WebSocketService,
        private animation: AnimationService,
        private config: ConfigService,
        private router: RouterService,
        private title: Title) {
        this.title.setTitle('Offline');
    }

    ngOnInit(): void {
        const message: WebSocketMessage = {
            type: "text",
            content: 'An error occurred, please check that the network connection is stable and try again.',
            language: 'en-US'
        }

        this.message.setMessage('ai', message);
    }

    async reload() {
        this.animation.destroyAnimationApp();
        await this.socket.reconnection();
        this.router.goToLanding(this.config.getProjectId()!)
    }
}
