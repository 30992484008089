import { createNgModule, Directive, Injector, Type, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appStartPage]'
})
export class StartPageDirective {

    constructor(private injector: Injector, public viewContainerRef: ViewContainerRef) {}

    async createComponent<C>(componentType: Type<C>) {
        const { ComponentModule } = await import("../component.module");
        return this.viewContainerRef.createComponent(
            componentType,
            {
                injector:this.injector,
                ngModuleRef: createNgModule(ComponentModule, this.injector)
            }
        );
    }

}
