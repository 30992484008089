<div class="box-error">
    <div class="error-container">
        <div class="error-inside-container">
            <div class="error-title">
                <div class="box-logo">
                    <div class="logo-container">
                        <div class="company-logo-container">
                            <img [src]="companyLogo" alt="Company Logo" />
                        </div>
                        <p class="company-name">{{ companyName }}</p>
                    </div>
                </div>
                <div class="error-text-container">
                    <div class="error-status-code">
                        <h1> {{ statusCode }}</h1>
                    </div>
                    <div class="error-description-container">
                        <p> {{ errorDescription }} </p>
                    </div>
                </div>
                <button id="id-homepage" type="button" class="homepage-button" (click)="onBackHomepageClick()"> {{ homepageButton }} </button>
            </div>
            <div class="error-image-container">
                <img [src]="errorImage" alt="Error image" />
            </div>
        </div>
    </div>
</div>
