import { Component } from '@angular/core';

import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router } from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    loading = false;

    constructor(private router: Router) {
        // Start and end loading while canActive is running
        this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckStart) {
                this.loading = true;
                console.log("ResolveStart")
            }
            if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                this.loading = false;
                console.log("ResolveEnd")
            }
        });
    }
}
